<template>
  <div>
    <NavTitle class="mb16" title="付费统计"></NavTitle>
    <div class="panel mt16">
      <el-form size="small" :inline="true">
        <div class="flex items-center">
          <el-form-item label="应用渠道">
            <el-select class="mr10" v-model="form.ch" placeholder="请选择渠道" clearable>
              <el-option
                v-for="item in channelList"
                :key="item.key"
                :label="item.value"
                :value="item.key">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="应用">
            <el-select class="mr10" v-model="form.rp" placeholder="请选择应用" clearable>
              <el-option
                v-for="item in rpList"
                :key="item.value"
                :label="item.key"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时间范围">
            <el-date-picker
              class="mr10"
              v-model="form.screenDate"
              value-format="yyyyMMdd"
              type="daterange"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="flex between-center">
          <div class="flex items-center">
            <el-form-item label="是否刷新数据">
              <el-switch v-model="form.refresh"></el-switch>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="getDataList">查询</el-button>
              <el-button class="ml16" type="primary" @click="exportToExcel">导出</el-button>
            </el-form-item>
          </div>
          <el-form-item>
            <div class="flex justify-end">
              <div class="flex items-center" v-if="isEdit">
                <el-button type="primary" size="small" @click="handleSave">保存数据</el-button>
                <el-button size="small" @click="handleCancel">取消</el-button>
              </div>
              <el-button type="primary" size="small" v-else @click="isEdit = true">编辑</el-button>
            </div>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div class="table-panel">
      <el-table
        :data="curList"
        style="width: 100%"
        border
        v-loading="loading"
        row-key="id"
        max-height="680px"
        :tree-props="{children: 'child'}"
        :header-cell-style="{background: '#f0f1f3', color: '#333'}"
        :show-summary="true"
        :summary-method="getSummaries"
        :row-class-name="tableRowClassName">
        <el-table-column
          prop="regDate"
          label="注册日期"
          width="150"
          fixed="left">
          <template slot-scope="scope">
            <span v-if="!scope.row.ch && !scope.row.rp">{{scope.row.regDate}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="dayOfWeek"
          label="星期"
          width="100"
          fixed="left">
        </el-table-column>
        <el-table-column
          prop="appName"
          label="应用"
          width="100"
          fixed="left">
        </el-table-column>
        <el-table-column
          prop="ch"
          label="渠道"
          width="100"
          fixed="left">
        </el-table-column>
        <el-table-column
          prop="spend"
          label="消耗金额"
          width="120">
          <template slot-scope="scope">
            <el-input v-if="isEdit && scope.row.ch && scope.row.rp" v-model="scope.row.spendInput" type="number" size="small"></el-input>
            <span v-else>{{scope.row.spend}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="memo"
          label="备注"
          width="200">
          <template slot-scope="scope">
            <el-input v-if="isEdit && scope.row.ch && scope.row.rp" type="textarea" v-model="scope.row.memoInput" placeholder="请输入备注内容" autosize></el-input>
             <span v-else>{{scope.row.memo}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="regPerson"
          label="注册数">
        </el-table-column>
        <el-table-column
          prop="regCost"
          label="注册成本">
        </el-table-column>
        <el-table-column
          prop="enrPerson"
          label="报名人数">
        </el-table-column>
        <el-table-column
          prop="enrNum"
          label="报名数">
        </el-table-column>
        <el-table-column
          prop="enrPostNum"
          label="报名职位数"
          width="100">
        </el-table-column>
        <el-table-column
          prop="enrRate"
          label="报名率%">
        </el-table-column>
        <el-table-column
          prop="formCost"
          label="报名成本(人)"
          width="105">
        </el-table-column>
        <el-table-column 
          prop="enrNumCost" 
          label="报名数成本"
          width="100">
        </el-table-column>
        <el-table-column
          prop="formPostPerson"
          label="报名单岗位报名人数"
          width="110">
        </el-table-column>
        <el-table-column
          prop="formNum"
          label="报名单岗位报名数"
          width="110">
        </el-table-column>
        <el-table-column
          prop="formPostNum"
          label="报名单岗位报名职位数"
          width="110">
        </el-table-column>
        <el-table-column
          prop="formPostRate"
          label="报名单岗位报名率%"
          width="110">
        </el-table-column>
        <el-table-column
          prop="avgFormNum"
          label="人均报名数"
          width="110">
        </el-table-column>
        <el-table-column
          prop="formCost"
          label="人数报名成本"
          width="110">
        </el-table-column>
        <el-table-column
          prop="formNumCost"
          label="岗位报名成本"
          width="110">
        </el-table-column>
        <el-table-column
          prop="cpcCost"
          label="cpc消耗"
          width="120">
        </el-table-column>
        <el-table-column
          prop="arpu"
          label="arpu">
        </el-table-column>
        <el-table-column
          prop="avgFormPrice"
          label="人均报名岗位单价"
          width="110">
        </el-table-column>
        <el-table-column
          prop="claim"
          label="赔付金额"
          width="110">
        </el-table-column>
        <el-table-column
          prop="income"
          label="实际收益"
          width="120">
        </el-table-column>
        <el-table-column
          prop="roi"
          label="ROI">
        </el-table-column>
        <el-table-column
          prop="costSpend"
          label="绝对值"
          width="120">
        </el-table-column>
        <el-table-column
          prop="flowProp"
          label="流量占比%">
          <template slot-scope="scope">
            <span>{{scope.row.flowProp}}%</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="costProp"
          label="消耗金额占比%">
          <template slot-scope="scope">
            <span>{{scope.row.costProp}}%</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="cpcProp"
          label="cpc消耗占比%">
          <template slot-scope="scope">
            <span>{{scope.row.cpcProp}}%</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="flex justify-center mt16">
        <el-pagination
          layout="total, prev, pager, next, sizes"
          :total="dataList.length"
          :page-size="page.size"
          :page-sizes="[5, 10, 15, 20]"
          @current-change="pageCurrentChange"
          @size-change="pageSizeChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import Pagination from '@/components/Pagination'
import { queryPayStatApi, modifyPayStatApi } from '@/api/state.js'
import { dictListApi } from '@/api/opera.js'
import { mapState } from 'vuex'
import { Workbook } from 'exceljs'
import fileSaver from 'file-saver'
export default {
  name: 'PayStatistics',
  components: {
    NavTitle,
    Pagination
  },
  data() {
    return {
      dataList: [],
      treeList: [],
      curList: [],
      totalData: [],
      channelList: [{key: '', value: '全部'}],
      form: {
        ch: '',
        rp: '',
        screenDate: [],
        refresh: false
      },
      rpList: [{key:'全部',value:''}],
      isEdit: false,
      loading: false,
      page: {
        current: 0,
        size: 10
      }
    }
  },
  computed: {
    ...mapState(['authChs'])
  },
  created() {
    this.getChannelList()
    this.getRpList()
  },
  methods: {
    getRpList() {
      dictListApi({
        code: 'postProductType',
        pageSize: 100,
        startPage: 0
      }).then(res => {
        console.log('rp列表', res)
        const list = res.data?.dataList[0]?.dictItem || []
        if(list.length > 0) this.rpList = this.rpList.concat(list)
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    getDataList() {
      if(!this.form.screenDate?.length) return this.$tips({message: '请选择时间范围', type:'error'})
      const formData = {
        ch: this.form.ch,
        rp: this.form.rp,
        startDay: this.form.screenDate[0],
        endDay: this.form.screenDate[1],
        refresh: this.form.refresh
      }
      console.log('formData', formData)
      this.loading = true
      queryPayStatApi(formData).then(res => {
        console.log('付费统计', res)
        const list = res.data.transStates || []
        this.totalData = list.pop()
        this.createDataList(list)
        this.loading = false
        console.log('this.dataList', this.dataList)
      }).catch(err => {
        this.$tips({message: err, type:'error'})
        this.loading = false
      })
    },
    createDataList(list) {
      const iteratorList = (list) => {
        for (const item of list) {
          item.id = this.getRandomId()
          if(item.child?.length > 0) {
            iteratorList(item.child)
          } else {
            Object.assign(item, {
              memoInput: item.memo, 
              spendInput: item.spend
            })
          }
        }
      }
      iteratorList(list)
      this.dataList = list
      this.getCurList()
    },
    getRandomId() {
      return Date.now().toString(36) + Math.random().toString(36).substring(2)
    },
    getChannelList() {
      console.log('授权渠道', this.authChs)
      // if(!this.authChs || this.authChs.length == 0) return
      dictListApi({
        code: 'channel',
        pageSize: 20,
        startPage: 0
      }).then(res => {
        console.log('渠道列表', res)
        const list = res.data?.dataList[0]?.dictItem || []
        if(list.length > 0) this.channelList = this.channelList.concat(list)
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    toNote(info) {
      this.$refs.notePopupRef.open({
        ch: info.ch,
        rp: this.form.rp,
        regDate: info.regDate,
        memo: info.memo
      })
    },
    createAllList(originList) {
      if(!originList?.length) return []
      const resList = []
      const iteratorFun = (list) => {
        for (const item of list) {
          if(item.child?.length > 0) {
            iteratorFun(item.child)
          } else {
            resList.push(item)
          }
        }
      }
      iteratorFun(originList)
      return resList
    },
    handleSave() {
      const formData = {
        modifies: []
      }
      const list = this.createAllList(this.dataList)
      for (const item of list) {
        if(!item.ch || !item.rp) continue
        const obj = {
          ch: item.ch,
          rp: item.rp,
          regDate: item.regDate,
          memo: item.memoInput,
          spend: Number(item.spendInput)
        }
        formData.modifies.push(obj)
      }
      console.log('formData', formData)
      if(this.dataList.length == 0 || formData.modifies.length == 0) return this.isEdit = false
      this.loading = true
      modifyPayStatApi(formData).then(res => {
        console.log('数据保存', res)
        this.$tips({message: res.msg, type: 'success'})
        this.loading = false
        this.isEdit = false
        const list = res.data.transStates || []
        this.totalData = list.pop()
        this.createDataList(list)
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
        this.loading = false
        this.isEdit = false
      })
    },
    handleCancel() {
      for (const item of this.dataList) {
        item.memoInput = item.memo
        item.spendInput = item.spend
      }
      this.isEdit = false
    },
    pageCurrentChange(e) {
      console.log('pageCurrentChange', e)
      this.page.current = e - 1
      this.getCurList()
    },
    pageSizeChange(size) {
      this.page.size = size
      this.getCurList()
    },
    getCurList() {
      if(this.dataList.length == 0) return this.curList = []
      const maxPage = Math.ceil(this.dataList.length / this.page.size)
      if(this.page.current + 1 > maxPage) this.page.current = maxPage - 1
      this.curList = this.dataList.slice(this.page.current * this.page.size, this.page.current * this.page.size + this.page.size)
      console.log('curList', this.curList, this.page.current)
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        } else {
          const wList = ['flowProp', 'costProp', 'cpcProp']
          sums[index] = `${this.totalData[column.property] || ''}${wList.includes(column.property) ? '%' : ''}`
        }
      })

      return sums;
    },
    exportToExcel() {
      if(!this.form.screenDate?.length) return this.$tips({message: '请选择时间范围', type:'error'})
      const formData = {
        ch: this.form.ch,
        rp: this.form.rp,
        startDay: this.form.screenDate[0],
        endDay: this.form.screenDate[1],
        refresh: this.form.refresh
      }
      queryPayStatApi(formData).then(res => {
        const list = this.createAllList(res.data.transStates)
        console.log('导出', list)
        const workbook = new Workbook()
        const worksheet = workbook.addWorksheet('Sheet1')
        worksheet.columns = [
          {header: '注册日期', key: 'regDate', width: 10},
          {header: '星期', key: 'dayOfWeek', width: 10},
          {header: '应用', key: 'cpc', width: 10},
          {header: '渠道', key: 'ch', width: 10},
          {header: '消耗金额', key: 'spend', width: 10},
          {header: '备注', key: 'memo', width: 10},
          {header: '注册数', key: 'regPerson', width: 10},
          {header: '注册成本', key: 'regCost', width: 10},
          {header: '报名人数', key: 'enrPerson', width: 10},
          {header: '报名数', key: 'enrNum', width: 10},
          {header: '报名职位数', key: 'enrPostNum', width: 10},
          {header: '报名率%', key: 'enrRate', width: 10},
          {header: '报名成本(人)', key: 'formCost', width: 10},
          {header: '报名数成本', key: 'enrNumCost', width: 10},
          {header: '报名单岗位报名人数', key: 'formPostPerson', width: 10},
          {header: '报名单岗位报名数', key: 'formNum', width: 10},
          {header: '报名单岗位报名职位数', key: 'formPostNum', width: 10},
          {header: '报名单岗位报名率%', key: 'formPostRate', width: 10},
          {header: '人均报名数', key: 'avgFormNum', width: 10},
          {header: '人数报名成本', key: 'formCost', width: 10},
          {header: '岗位报名成本', key: 'formNumCost', width: 10},
          {header: 'cpc消耗', key: 'cpcCost', width: 10},
          {header: 'arpu', key: 'arpu', width: 10},
          {header: '人均报名岗位单价', key: 'avgFormPrice', width: 10},
          {header: '赔付金额', key: 'claim', width: 10},
          {header: '实际收益', key: 'income', width: 10},
          {header: 'ROI', key: 'roi', width: 10},
          {header: '绝对值', key: 'costSpend', width: 10},
          {header: '流量占比%', key: 'flowProp', width: 10},
          {header: '消耗金额占比%', key: 'costProp', width: 10},
          {header: 'cpc消耗占比%', key: 'cpcProp', width: 10},
        ]
        worksheet.addRows(list)
        workbook.xlsx.writeBuffer().then(buffer => {
          fileSaver(new Blob([buffer], {type: 'application/octet-stream'}), `付费统计${formData.startDay}-${formData.endDay}.xlsx`)
        })
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
    tableRowClassName({row, rowIndex}) {
      if (row.child?.length > 0 && !row.rp) {
        return 'first-level-row'
      } 
      else if(row.child?.length > 0 && row.rp) {
        return 'second-level-row'
      }
      else {
        return 'third-level-row'
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.el-table {
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    border-radius: 32px;
  }

  ::-webkit-scrollbar-track {
    background-color: #F6F6F6;
    border-radius: 32px;
  }
}
.panel {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px 24px 0;
  margin-left: 20px;
  margin-right: 20px;
}
.table-panel {
  background: #fff;
  padding: 10px;
  margin: 16px 20px 0;
}
.total-cell {
  font-weight: bold;
}
::v-deep .second-level-row {
  background: #fdfdfd;
}
::v-deep .third-level-row {
  background: #f9f9f9;
}
::v-deep .el-table__footer {
  font-weight: bold;
}
</style>